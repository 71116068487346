.fab{
    position: absolute !important;
    bottom: 5%;
    right: 5%;   
}

.modal{
    /* display: 'flex';
    align-items: 'center';
    justify-content: 'center'; */

    width: 40%;
    margin: auto;
    margin-top: 100px;
}


.mainViewDiv{
    width: 100%;
    height: 100%;
}


.fabImage{
    position: absolute !important;
    top: 5%;
    left: 2%;  
}

.imageModal{
    width: 70%;
    margin: auto;
    margin-top: 50px;
}