div.switch-container{
  width: 100%;
}
/*
div.switch-container > div:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

div.switch-container > div:nth-child(1) > div {
  text-align: center;
  margin: 10px;
}

div.switch-container > div:nth-child(1) > div > div {
  width: 70%;
}

div.switch-container > div:nth-child(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.switch-container > div:nth-child(2) > div:nth-child(1) {
  text-align: right;
} 

.switch-container > div:nth-child(2) > div:nth-child(3) > p:nth-child(1),
.switch-container > div:nth-child(2) > div:nth-child(1) > p:nth-child(1) {
  font-size: 1.5em;
}

.switch-container > div:nth-child(2) > span:nth-child(2) {
  margin: auto;
}*/

.cam-btn-container {
  height: 100%;
  padding: 100% 0;
}

button.cam-btn {
  height: calc(100% - 40px);
  margin: 20px 0 0 10px;
}

button.cam-btn.active {
  background-color: red;
}

#canvas-video-container {
  position: relative;
  overflow: hidden;
}

#canvas-video-container > video {
  position: absolute;
  z-index: -2;
  min-height: 50vh;
}

#canvas-video-container > canvas {
  width: 100%;
  background-color: transparent;
  display: block;
  z-index: 1;
  /* padding: 25% 0; */
}

#canvas-video-container > img {
  /* display: none; */
  position: absolute;
  z-index: -1;
}

#canvas-video-container > img.hidden {
  display: none;
}

#bottom-container {
  padding: 20px;
}

#bottom-container > button {
  margin: auto;
}
